<template>

  <div
    class="app-news-detail"
    role="content">

    <p
      class="preview-title">{{ title }}
    </p>

    <p
      class="preview-info">

      <span
        class="datetime">time: {{ date }}
      </span>

    </p>

    <el-divider>
    </el-divider>

    <div
      v-html="content"
      class="rich-text news">
    </div>

  </div>

</template>

<script>
import * as api from '@/configs/api'
export default {
  name: 'app-news-detail',
  data () {
    return {
      author: 'Ocean big data center',
      browsing: 198,
      content: '2222',
      date: 'Jan 14, 2021',
      id: '212312311123',
      source: '',
      title: 'Global 14 units and scholars jointly released "2020 annual report" on global ocean warming'
    }
  },
  watch: {
    $route: 'fetchData'
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const query = this.$route.query
      const url = api.application.detail
      const params = {
        Id: query.id
      }
      this.axios.get(url, {
        params
      }).then(response => {
        const res = response.data
        if (res.code === 200) {
          this.title = res.data.title
          this.date = res.data.createTime.replace(/T/, ' ').replace(/\..*/, '')
          this.content = res.data.richContent
        }
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-news-detail {
  background-color: #FFFFFF;
  box-sizing: border-box;
  margin: {
    bottom: 30px;
    top: 60px;
  };
  padding: {
    bottom: 20px;
    left: 20px;
    right: 20px;
    top: 20px;
  };
}

.preview-title {
  color: dodgerblue;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 25px;
  margin-top: 25px;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.preview-info {
  color: darkgray;
  font-size: 14px;
  margin-bottom: 15px;
  margin-left: 25px;
  margin-top: 15px;
  text-align: center;
}
.preview-info .datetime {
  margin-right: 25px;
}
.rich-text {
  padding-left: 29px;
  padding-right: 29px;
  text-align: left;
}
</style>
